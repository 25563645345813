<template>
  <section class="container m-padded">
    <div class="grid">
      <MobileScrollWidthBox>
      <div class="item">
        <img src="./dial@2x.jpg"/>
        <h1>Urskive med bølge- og radarmønster, tauverk og skvadronens offisielle logo.</h1>
      </div>
      </MobileScrollWidthBox>
      <MobileScrollWidthBox>
      <div class="item">
        <img src="./hands@2x.jpg"/>
        <h1 class="wide">Store indekser og visere med Super-LumiNova for optimal lesbarhet.</h1>
      </div>
      </MobileScrollWidthBox>
      <MobileScrollWidthBox>
      <div class="item">
        <img src="./rehaut@2x.jpg"/>
        <h1>Rehaut inngravert med “For Konge, Fedreland og Flaggets heder siden 2006”.</h1>
      </div>
      </MobileScrollWidthBox>
      <MobileScrollWidthBox>
      <div class="item">
        <img src="./crown@2x.jpg"/>
        <h1>Krone dekorert med “3” – første tall i Fridtjof Nansen-klassens pennantnummer.</h1>
      </div>
      </MobileScrollWidthBox>
    </div>
  </section>
</template>

<script>
import MobileScrollWidthBox from "~/components/ui/MobileScrollWidthBox"

export default {
  name: 'FeatureGrid',
  components: {MobileScrollWidthBox}
}
</script>

<style lang="scss" scoped>
@import "~/css/system.scss";

section {
  margin-top: .625rem;
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.625rem;

    @include m {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0.625rem;
    }

    .item {
      background-color: black;
      padding: 1.75rem 0rem;
      margin: 0rem $gutter;
      @include m {
        padding: 4.37rem 0rem;
        margin: 0rem 4.37rem;
      }
      text-align: center;

      h1 {
        text-align: center;
        width: 80%;
        margin: 0px;
        padding: 0px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.5rem;
        margin-bottom: .9rem;
        @include m {
          width: 80%;
          &.wide {
            width: 100%;
          }
          margin-top: 2.7rem;
          margin-bottom: 0;
        }
      }

      img {
        width: 80%;
        @include m {
          width: 95%;
        }
      }
    }
  }
}
</style>
