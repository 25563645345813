export const KLOKKEMODELL = "Nansen";
export const URVERK = "Sellita";
export const GANGRESERVETIMER = 42;
export const SAMARBEIDSPARTNER = "1. FREGATTSKVADRON"
export const PRICE = "9.450"
export const DELBETALING_PRICE = "1.575";
export const KJOP_URL = "https://checkout.micromilspec.com/fccae8ae-2351-4e2c-b8d4-cb4a138280d3";

export const FAQ = [
  {
    title: `Hvem kan kjøpe klokken?`,
    value: `${KLOKKEMODELL} er kun tilgjengelig for ${SAMARBEIDSPARTNER} og aktivt personell eller veteraner med minimum 3 års aktiv tjeneste.`,
  },
  {
    title: `Hvorfor koster ${KLOKKEMODELL} ${PRICE} kroner?`,
    value: `${KLOKKEMODELL} er en spesialprodusert klokke som er konstruert for å vare i generasjoner. Flere av de essensielle komponentene er produsert på maskiner som er satt opp og kalibrert spesielt for denne klokken. ${KLOKKEMODELL} eksisterte ikke fra før – og vil heller ikke reproduseres senere utenfor avdelingen.

Urverket er mekanisk og produseres i Sveits etter strenge kvalitetskriterier. ${URVERK} er anerkjent som en presis og robust arbeidshest, og benyttes hos flere av verdens ypperste klokkeprodusenter.`,
  },
  {
    title: `Tilbyr dere delbetaling?`,
    value: `Ja, og du kan velge mellom betalingsintervaller på 2 eller 6 måneder.`,
  },
  {
    title: `Hva menes med “Swiss Made”?`,
    value: `Som en videreføring sveitsiske håndverkstradisjoner og fokus på kvalitet, er klokken produsert i tråd med vedtekene om “Swiss Made” (innført 1. januar 2019), som definert av det sveitsiske Forbundsrådet.

“Swiss Made” er i dag en beskyttet designasjon. Kriteriene krever at klokken benytter et sveitsisk urverk, samt at minst 60 prosent av klokken (og komponentene) er produsert i Sveits. Sluttmontering og siste inspeksjon må også skje i Sveits. Slik sikres den høye kvaliteten man forventer av en sveitsisk klokke.`,
  },
  {
    title: `Hva menes med “mekanisk urverk” og “selvtrekkende”?`,
    value: `En mekanisk klokke drives av en opptrekkbar fjær – ikke elektronikk og batterier. Klokken kan trekkes opp for hånd, men vil også trekkes når den er i bruk. Gjennom et perfekt kalibrert mekanisk samspill, sørger bevegelsen til et lite lodd (den såkalte “rotoren”) for at drivfjæren trekkes opp. Fullt opptrukket har klokken en gangreserve på ${GANGRESERVETIMER} timer.`,
  },
  {
    title: `Når er klokken min klar for levering?`,
    value: `Forventet produksjon og leveringstid er minimum 24 uker. Det er i tillegg beregnet potensielle forsinkelser på 60 dager grunnet Covid-19.`,
  },
  {
    title: `Hva dekker deres 5 års internasjonale garanti?`,
    value: `Vår klasseledende garanti dekker alle produksjonsfeil, inkludert komponenter som urverk, urskive, visere og rem. Kort og godt – er det vår skyld at klokken ikke fungerer som den skal, utbedrer vi feilen eller erstatter klokken i sin helhet. Garantien dekker imidlertid ikke feil og mangler som følge av brukerpåførte skader og feilaktig bruk. Bruksslitasje, riper, vannskade og tyveri dekkes heller ikke.

Vær spesielt oppmerksom på at lærremmer skades av vann og fuktighet – også når selve klokken tåler vann. En periodisk trykktest av klokken hvert 1-2 år er dessuten nødvendig for å verifisere at klokken fortsatt er vannresistent.

Dersom en klokke returneres i løpet av garantiperioden, vil MICROMILSPEC reparere eller erstatte klokken og komponenter kostnadsfritt, og innen rimelig tid. Kunden kan imidlertid bli ansvarlig for å dekke eventuelle fraktkostnader.`,
  },
  {
    title: `Trenger jeg å trekke klokken eller å skifte batteri på den?`,
    value: `Nei, klokken er mekanisk og selvtrekkende. Ved hjelp av kroppens bevegelse vil klokken trekkes automatisk på håndleddet. Full gangreserve på 42 timer oppnås ordinært etter rundt 4 til 5 timers bruk. Klokken kan også trekkes opp manuelt ved å vri på kronen, hvor en tydelig følt endring i friksjon signaliserer at urverket er fullt opptrukket.`,
  },
  {
    title: `Hvor kan jeg levere klokken til service og hvor mye koster det?`,
    value: `${KLOKKEMODELL} har ingen elektronikk som kan bli avleggs, men består utelukkende av mekaniske komponenter som kan repareres eller erstattes. Ved riktig vedlikehold og service vil klokken kunne vare i generasjoner.

Alle mekaniske urverk bør renses, oljes og justeres, og vårt anbefalte serviceintervall er 5 år (i tråd med de fleste mekaniske klokker). En slik periodisk overhaling vil ordinært koste rundt 4.000 kroner hos en urmaker. Vi tilbyr imidlertid full service av ${KLOKKEMODELL} gjennom vår MICROMILSPEC-godkjente urmaker for 2.500 kroner.`,
  },
  {
    title: `Tåler klokken ekstreme værforhold og ytre påkjenninger?`,
    value: `Ja – men den tåler ikke kraftige sammenstøt, fall eller slag mot harde overflater som betong, fliser og stein. Dette kan resultere i ytre skader som riper, hakk og knust glass – men også skader på skjulte innvendige komponenter.`,
  },
  {
    title: `Hvordan tar jeg best mulig vare på min klokke?`,
    value: `En egen link med instruksjoner for bruk og vedlikehold sendes til e-postadressen som er registrert på din ordre.`,
  },
  {
    title: `Kan ${KLOKKEMODELL} poleres?`,
    value: `Ja og nei. Klokken kan poleres, men den grå PVD finisjen vil forsvinne.`,
  }
];
