<template>
  <a :href="KJOP_URL"><button class="primary"><slot>Kjøp nå</slot></button></a>
</template>

<script>
import { KJOP_URL } from "../../data.js";

export default {
  data() {
    return {
      KJOP_URL,
    }
  },
}
</script>
