<template>
  <div class="live-order-count text-sm block text-center py-3">
    <span class="text-yellow-600">{{ remaining }}</span> / 310 klokker tilgjengelig
  </div>
</template>

<style>
    .live-order-count {
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        padding: 8px;
    }

    .live-order-count span {
        color: #FFD300;
    }
</style>

<script>
import axios from "axios"

export default {
  props: {
    quota: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      count: 0
    }
  },

  mounted () {
    this.fetchData()

    setInterval(() => {
        this.fetchData()
    }, 30000)
  },

  methods: {
      fetchData () {
        axios.get("https://checkout.micromilspec.com/nansen/order-count")
            .then(response => {
                this.count = response.data.count
            })
      }
  },    

  computed: {
    remaining () {
      return this.count <= this.quota ? (this.quota - this.count) : 0
    }
  }
}
</script>
